

57
function Video() {
    function createIframe() {
        return {__html: '<div class="r1_iframe_embed"><iframe src="https://player.arvancloud.ir/index.html?config=https://mahsoolforooshi.arvanvod.ir/EnM2n7XdPA/q2wjRDVg4l/origin_config.json" style="border:0 #ffffff none;" name="445b9cc1364501a932c793cf022efc6b.mp4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>'};
    }

    return(
        <div dangerouslySetInnerHTML={createIframe()}></div>
    )
}

export default Video