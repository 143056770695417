import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { ReactComponent as DownArrow } from './down-arrow-svgrepo-com (1).svg'
import { useState, useEffect } from 'react';
import Video from './Video';

function Intro() {
  const [initDataUnsafe, initData] = useInitData();
  return (
    <div className='Intro'>
      <p>{initDataUnsafe.user.first_name} جان سلام</p>
      <p>
        لطفا اشتراک خود را تهیه کنید.
      </p>
    </div>
  )
}

function Section(props) {
  return <div className='Section'>
    <p>{props.title}</p>
    <a href='https://s3.ir-thr-at1.arvanstorage.ir/mahsoolforooshi/The_Short_Films_of_David_Lynch_2002_Part2_DVDRip_HighCode_30NAMA.mkv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=896e1a25-bb1f-43c5-9899-4f4a1299b336%2F20240917%2Fir-thr-at1%2Fs3%2Faws4_request&X-Amz-Date=20240917T151804Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&versionId=&X-Amz-Signature=aa3a8b90400003739406ff8329134140437951ab25a27b37435f99f00bd52a59' className='Section-download'>↓Download</a>
  </div>
}

function Chapter(props) {
  console.log(props.title)
  const [expanded, setExpanded] = useState(false)
  const expandSection = () => {
    if (expanded == true) {
      setExpanded(false)
    }
    else {
      setExpanded(true)
    }
  }
  let DownArrowStyles
  let DescriptionStyles
  if (expanded==true) {
    DownArrowStyles = {
      'animation-name': 'upsidedown',
      transform: 'rotate(180deg)'
  }
    DescriptionStyles = {
      'animation-name': 'expandDescriptionText',
      display: 'block',
      'max-height': '100rem'
    }
  }else{
    DownArrowStyles = {
      'animation-name': 'downsideup',
      transform: 'rotate(0deg)'
  }
    DescriptionStyles = {
      display: 'none'
    }
  }
  return (
    <div className='Chapter'>
      <div className='Chapter-heading' onClick={expandSection}>
        <p>
          {props.title}
        </p>
        <DownArrow className="Chapter-arrow" style={DownArrowStyles} />
      </div>
      <p className='Chapter-description' style={DescriptionStyles}>
        {props.description}
        {props.sections.map((section) => {return(<Section title={section.title} file_path={section.file_path}/>)})}
      </p>
      <Video></Video>
    </div>

  )
}

function ChapterList(props) {
  return (<div>
    {props.chapters.map((chapter) => {return <Chapter title={chapter.title} key={chapter._id} description={chapter.description} sections={chapter.sections}/>})}
    </div>
  )
}


function App() {
  const [initDataUnsafe, initData] = useInitData();
  const [chapters, setChapters] = useState([]);
  let jsonData = {
    "init_data": initData,
    "hash_str": initDataUnsafe.hash
  }
  fetch('https://scambot.levothyroxine.uno/api/get_user', {  // Enter your IP address here

    method: 'POST', 
    mode: 'cors', 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(jsonData) // body data type must match "Content-Type" header

  })

  useEffect(() => {
  axios.get('https://scambot.levothyroxine.uno/api/get_chapters')
    .then(response => {
      console.log('I ran!!!!!!!')
      setChapters(response.data)
    })
    .catch(error => {
      console.error(error);
    })}, []
  )
  console.log(chapters)
  return (
    <div className="App" dir="rtl">


      <div className='Wrapper'>

        <Intro />

        {chapters && <ChapterList chapters={chapters}/>}
        {/* <Chapter title="مقدمه" />
        <Chapter title="آموزش ها" />
        <Chapter title="منابع" /> */}
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            This is the first baby step to create this bot
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>

      </div>
    </div>
  );
}


export default App;
