import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MainButton, useShowPopup, useInitData } from '@vkruglikov/react-telegram-web-app';

const Content = () => {
  const showPopup = useShowPopup();
  const [initDataUnsafe, initData] = useInitData();
  const handleClick = () =>
    showPopup({
      message: initDataUnsafe.user.first_name,
    });

  return <MainButton text="SHOW POPUP" onClick={handleClick} />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Content />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
